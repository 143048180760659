var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        "is-preview": false,
        isMaskClosable: _vm.isMaskClosable,
        "hide-edit-btn": _vm.hideEditBtn,
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: _vm.permission,
        btnLoading: _vm.btnLoading,
        "confirm-btn-disable": _vm.confirmBtnDisable,
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
        onConfirm: _vm.onConfirm,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "scroll-view",
          class: {
            "allow-scroll": _vm.isPreview,
            "no-scroll": _vm.isFrameHover,
          },
          attrs: { slot: "form" },
          slot: "form",
        },
        [
          _c("a-table", {
            attrs: {
              rowSelection: _vm.rowSelection,
              rowKey: "id",
              columns: _vm.columns,
              dataSource: _vm.dataSource.filter((item) => {
                return _vm.isView(item)
              }),
            },
            on: { change: _vm.handleTableChange },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }